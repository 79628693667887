@import '~sanitize.css';
@import '~tippy.js/dist/tippy.css';
@import './assets/variables.css';
@import './assets/master';
@import './assets/animations';
@import './assets/tippy.scss';

html,
body,
#root,
#app-root {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  font-family: var(--primaryfont), sans-serif;
  font-size: 12px;

  --desktopMinSize: #{$desktopMinSize};

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f2f2f2;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(35, 35, 35, 0.5);
    border-radius: 8px;
  }
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: var(--color2-lightest);

  & > header,
  header.top-page {
    flex-shrink: 0;
    width: 100%;
    height: auto;

    nav {
      ul {
        margin: 0;
        padding: 0;

        li {
          width: 60px;
          height: 60px;
          padding: 0;
          margin-right: 5px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }

          .icon {
            width: 30%;
            height: 30%;

            svg {
              fill: var(--color1);
            }
          }
        }
      }
    }
  }

  .strikethrough {
    font-weight: 100;
    text-decoration: line-through;
    margin-right: 8px;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }

  .highlight-tag {
    background-color: #84a648;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }

  .logo-container {
    padding: 10px 15px;
    height: 60px;
    box-sizing: border-box;
    background-color: var(--color3-alpha-medium);

    img {
      max-height: 40px;
      flex-shrink: 0;
      max-width: 100%;
    }

    label {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  & > section {
    flex: 0 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px 60px;

    & > header {
      margin-bottom: 30px;

      .row.bottom-border {
        border-bottom: 2px solid var(--color1);
        h1 {
          border: none;
        }
      }

      h1 {
        font-size: 28px;
        padding: 20px;
        margin: 0;
        border-bottom: 2px solid var(--color1);
        color: var(--color1);
      }

      p {
        font-size: 12px;
        border-left: 2px solid var(--color1);
        padding: 15px;
        margin: 0;
        max-width: 33vw;
      }
    }
  }
}

$dotSize: 2.5em;
$animDelay: 0.3s;
.threedotloader {
  width: $dotSize * 4;

  .dot {
    width: $dotSize;
    height: $dotSize;
    margin: 0 3px;
    border-radius: $dotSize;
    background-color: var(--color1);
    transform: scale(0);
    display: inline-block;
    animation: dotanim 2s infinite ease-in-out;
    @for $i from 0 to 4 {
      &:nth-child(#{$i + 1}) {
        animation-delay: $animDelay * $i;
      }
    }
  }
}

.page-overlay {
  @include absolute(top 0 right 0 bottom 0 left 0);
  z-index: 9;
  background-color: var(--transparent-gray);
  backdrop-filter: blur(1px);
  @include animation(fadeIn 250ms ease-in);
  overflow: hidden;

  &.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.see-thru {
    background-color: transparent;
  }

  &.click-thru {
    pointer-events: none;
  }

  &.no-blur {
    backdrop-filter: unset;
  }
}

.flex {
  display: flex;

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-sb {
    justify-content: space-between;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.allow-wrap {
    flex-wrap: wrap;
  }

  .push-right {
    margin-left: auto;
  }
}

.align-self-center {
  align-self: center;
}

.no-shrink {
  flex-shrink: 0;
}

.no-grow {
  flex-grow: 0;
}

.do-grow {
  flex-grow: 1;
}

.margin-bottom {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  cursor: pointer;

  img {
    pointer-events: none;
  }
}

// Hack so fractions have the same line height as regular text
sup,
sub {
  vertical-align: baseline;
  position: relative;
}
sup {
  top: -0.4em;
}
sub {
  top: 0.4em;
}

button {
  border: none;
  padding: 0;
  background-color: initial;
}

input,
textarea {
  padding: 10px;
  border: none;
  background-color: var(--color2);
  outline: none;
  font-size: 14px;
  @include transition(box-shadow 200ms ease-out);

  &:focus {
    @include box-shadow(0px 3px 8px 1px var(--color1-alpha-low));
  }
}

.btn {
  cursor: pointer;
  @include user-select(none);
  outline: none;
  text-align: left;
  @include transition(all 150ms ease-in);
  @include box-sizing(border-box);
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  background-color: var(--color4);
  padding: 10px;

  &:hover {
    background-color: var(--color4-darker);
  }

  &.priority {
    background-color: var(--color1);
    color: var(--color2);
    text-transform: uppercase;

    &:hover {
      background-color: var(--color1-lighter);
    }
  }

  &.positive {
    background-color: $positive;
    color: var(--color2);
    text-transform: uppercase;

    &:hover {
      background-color: darken($positive, 10%);
    }

    .icon {
      margin-right: 8px;

      svg {
        fill: var(--color2);
      }
    }
  }

  &.quick {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 7px;
    justify-content: center;

    .icon {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }

  &.block {
    width: 60px;
    height: 60px;
    padding: 0;
    justify-content: center;
    background-color: var(--color4);

    &:hover {
      background-color: var(--color4-darker);
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid var(--color1);
    color: var(--color1);

    &:hover {
      background-color: var(--color3-darker);
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.text-only {
    background: none;
    font-size: 14px;
    font-weight: bold;
    padding: 0;

    &:hover {
      color: var(--color1-lightest);
    }
  }

  &.full-width {
    width: 100%;
    label {
      margin: auto;
    }
  }

  .icon {
    width: 16px;
    height: 16px;

    svg {
      fill: var(--color1);
    }
  }

  label {
    cursor: inherit;
  }
}

.alternate1-template {
  button.btn {
    text-transform: none;
    border-radius: 17px;
    padding: 10px 25px;

    label {
      font-size: 14px;
    }

    &.priority {
      background-color: var(--color4);
      border: 1px solid var(--color4);

      &:hover {
        background-color: var(--color4-darker);
      }

      &.disabled {
        background-color: var(--color4-lighter);
        pointer-events: none;
      }
    }

    &.large {
      border-radius: 25px;
      padding: 15px;

      label {
        font-size: 16px;
      }
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      width: calc(50% - 10px);
      margin: 0;
      label {
        margin: auto;
      }
    }
  }

  #progress-window {
    border-radius: 25px;
  }
}

.icon {
  width: 1em;
  height: 1em;

  &.rotate-180 {
    svg {
      @include transform(rotate(180deg));
    }
  }

  &.rotate-45 {
    svg {
      @include transform(rotate(45deg));
    }
  }

  svg {
    fill: var(--color1);
    width: 100%;
    height: 100%;
    display: block;
  }
}

.fadeIn {
  @include animation(fadeIn 200ms ease-in);
}

@import '../../assets/master';

.modal {
  background-color: var(--color3-darker);
  border-radius: 4px;
  padding: 2rem;
  text-align: center;
  box-shadow: 1px 4px 9px 0px #404040;

  &.qr-container {
    display: flex;
  }
  .close {
    color: red;
    border: 2px solid red;
  }

  &.form {
    background-color: var(--color2);
    width: 75vw;
    max-width: 1000px;
    max-height: 100vh;
    overflow-y: auto;
    @include compactPortraitOnly {
      width: 95vw;
    }

    header {
      @include compact {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .logo {
        width: 30%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
      }

      img {
        max-height: 100px;
        max-width: 450px;
        object-fit: contain;
        margin-bottom: 1rem;
      }

      hgroup {
        h1,
        h4 {
          margin-top: 0;
          text-align: left;
          @include compactPortraitOnly {
            text-align: center;
          }
        }

        h1 {
          font-size: 32px;
          @include compactPortraitOnly {
            font-size: 1.4rem;
          }
        }

        h4 {
          color: var(--color1-lightest);
        }
      }
    }

    .form-row {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      & > .field {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 50%;

        textarea {
          width: 100%;
          height: 120px;
          resize: none;
        }

        input[type='number'] {
          background-color: white;
          border: 1px solid #8a8a8a;
          border-radius: 4px;
          &::-webkit-inner-spin-button {
            opacity: 1;
          }
        }

        input,
        textarea,
        select {
          background-color: white;
          border: 1px solid #8a8a8a;
          border-radius: 4px;
        }

        .location-fields {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .field {
            margin-bottom: 0;
          }

          select {
            padding: 5px;
            flex-grow: 1;

            &:focus {
              outline: none;
              @include box-shadow(0px 3px 8px 1px var(--color1-alpha-low));
            }
          }

          .city {
            width: 50%;
          }

          .state {
            width: 15%;
            @include compact() {
              width: 20%;
            }
          }

          .zip {
            width: 25%;
            input {
              -moz-appearance: textfield;
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }

        .field {
          width: 100%;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
        &.validation-error {
          input {
            border-color: red;
          }
          span {
            font-weight: bold;
            color: red;
            margin: 5px 0 0 5px;
          }
        }
      }

      @media (max-width: 530px) {
        margin-bottom: 0;

        & > .field {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .field {
      &.bordered-section {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 2px solid var(--color4);
        border-bottom: 2px solid var(--color4);
      }

      input,
      textarea {
        display: inline-block;
        background-color: var(--color3-darker);
        // Work around to eliminate iOS native zoom on inputs
        @include compact {
          font-size: 16px;
        }
      }

      input[type='checkbox'] + label {
        display: inline-block;
        margin-left: 5px;
        margin-bottom: 0;
        width: auto;
      }

      p {
        font-size: 12px;
        color: var(--color1-lightest);
        margin-bottom: 0;
      }
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 14px;
  }

  .field {
    display: inline-flex;
    flex-direction: column;
    text-align: left;

    label {
      color: var(--color1-lightest);
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: bold;
    }
  }
  .btn-group {
    display: flex;
    justify-content: space-evenly;
  }

  .btn {
    margin: 0 5px;
    width: 100px;
    justify-content: center;
    white-space: nowrap;

    &:last-child {
      .icon svg {
        fill: var(--color1);
      }
    }

    .icon {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
  }
}

.alternate1-template {
  .modal {
    background-color: var(--color2);
    color: var(--color1);
    box-shadow: 0 2px 4px 2px rgba(205, 205, 205, 0.3);

    h2 {
      font-weight: 400;
      font-size: 24px;
      letter-spacing: -0.4px;
      margin: 0 0 2rem;
    }
  }
}

.qr-container {
  background-color: var(--color4);
  border: 1px solid var(--color1);
  position: relative;
  padding: 2rem 4rem 3rem 4rem;
  text-align: center;
  box-shadow: 1px 4px 9px 0px var(--color1-lightest);
  @include animation(slideUpFadeIn 200ms ease-in);
  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    margin: 10px;
    svg {
      box-shadow: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  h1,
  h2,
  h3 {
    font-size: 2rem;
    margin: 0;
  }
  p {
    font-size: 1rem;
    margin: 0;
    margin-bottom: 25px;
  }
  svg {
    box-shadow: 1px 4px 9px 0px var(--color1-lightest);
  }
}

.alert-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 50%;
  background-color: var(--color3);
  border-radius: 1rem;
  box-shadow: 1px 4px 9px 0px var(--color1-lightest);
  .alert-icon {
    color: rgb(227, 201, 6);
    width: 25%;
  }
  h1,
  h2,
  h3 {
    font-size: 2rem;
    text-align: center;
  }
  p {
    font-size: 1rem;
    text-align: center;
  }
  @include compactLandscapeOnly {
    h1,
    h2,
    h3 {
      font-size: 1.5rem;
    }
  }
  @include compactPortraitOnly {
    width: 80%;
    h1,
    h2,
    h3 {
      font-size: 1.5rem;
    }
  }
}

.standard-modal {
  &.page-overlay {
    background-color: var(--v2-overlay-bkg);
    animation: none;
  }

  &.warning {
    .modal > header .header-icon {
      background-color: var(--v2-warning-bkg);
      fill: var(--v2-warning-frg);
      padding: 5px;
      border-radius: 1rem;
      height: 25px;
      width: 25px;
    }
  }

  .modal {
    max-width: 90%;
    max-height: 90%;
    background-color: var(--v2-modal-bkg);
    color: #000;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: none;

    & > header {
      flex: 0 0 auto;
      width: 100%;
      padding: 15px;
      box-shadow: 0px 2px 8px var(--color1-alpha-low);

      .header-icon {
        width: 20px;
        margin-right: 1rem;
        fill: #000;
      }

      .close-icon {
        width: 20px;
        margin-left: 1rem;

        &:hover {
          cursor: pointer;
        }
      }

      svg {
        width: 100%;
      }
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }

    & .modal-content {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-y: overlay;
    }
  }
}

.landscape-warning-modal {
  display: none;

  @media screen and (orientation: landscape) {
    display: unset;
  }
  .modal {
    max-width: 60%;
  }

  .landscape-warning-modal-content {
    padding: 30px;
    text-align: left;
    line-height: 1.2rem;

    h2 {
      font-size: 1.1rem;
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.1rem;
    }
  }
}

.global-confirm-modal {
  .modal-content {
    padding: 1.5rem;
  }

  .btn-group {
    margin-top: 1rem;
  }
}

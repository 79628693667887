.tippy-box {
  $background: var(--color2);
  $foreground: var(--color1);
  $borderColor: var(--color4);
  $boxShadowColor: var(--transparent-gray);

  /* Theme customized from Tippy's built-in light-border theme */

  background-color: $background;
  background-clip: padding-box;
  border: 1px solid $borderColor;
  color: $foreground;
  box-shadow: 0 4px 14px -2px $boxShadowColor;
  padding: 0.5rem;
  font-size: 13px;

  & > .tippy-backdrop {
    background-color: $background;
  }
  & > .tippy-arrow:after,
  & > .tippy-svg-arrow:after {
    content: '';
    position: absolute;
    z-index: -1;
  }
  & > .tippy-arrow:after {
    border-color: transparent;
    border-style: solid;
  }
  &[data-placement^='top'] > .tippy-arrow:before {
    border-top-color: $background;
  }
  &[data-placement^='top'] > .tippy-arrow:after {
    border-top-color: $borderColor;
    border-width: 7px 7px 0;
    top: 17px;
    left: 1px;
  }
  &[data-placement^='top'] > .tippy-svg-arrow > svg {
    top: 16px;
  }
  &[data-placement^='top'] > .tippy-svg-arrow:after {
    top: 17px;
  }
  &[data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: $background;
    bottom: 16px;
  }
  &[data-placement^='bottom'] > .tippy-arrow:after {
    border-bottom-color: $borderColor;
    border-width: 0 7px 7px;
    bottom: 17px;
    left: 1px;
  }
  &[data-placement^='bottom'] > .tippy-svg-arrow > svg {
    bottom: 16px;
  }
  &[data-placement^='bottom'] > .tippy-svg-arrow:after {
    bottom: 17px;
  }
  &[data-placement^='left'] > .tippy-arrow:before {
    border-left-color: $background;
  }
  &[data-placement^='left'] > .tippy-arrow:after {
    border-left-color: $borderColor;
    border-width: 7px 0 7px 7px;
    left: 17px;
    top: 1px;
  }
  &[data-placement^='left'] > .tippy-svg-arrow > svg {
    left: 11px;
  }
  &[data-placement^='left'] > .tippy-svg-arrow:after {
    left: 12px;
  }
  &[data-placement^='right'] > .tippy-arrow:before {
    border-right-color: $background;
    right: 16px;
  }
  &[data-placement^='right'] > .tippy-arrow:after {
    border-width: 7px 7px 7px 0;
    right: 17px;
    top: 1px;
    border-right-color: $borderColor;
  }
  &[data-placement^='right'] > .tippy-svg-arrow > svg {
    right: 11px;
  }
  &[data-placement^='right'] > .tippy-svg-arrow:after {
    right: 12px;
  }
  & > .tippy-svg-arrow {
    fill: $background;
  }
  & > .tippy-svg-arrow:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
    background-size: 16px 6px;
    width: 16px;
    height: 6px;
  }
}

.tippy-popup {
  ul {
    padding: 10px;
    margin: 0 1rem;
  }
}

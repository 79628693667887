@keyframes dotanim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  90% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expandingBottomHighlight {
  0% {
    width: 0%;
    left: 50%;
    border: 3px solid var(--v2-warning-bkg);
  }
  30% {
    width: 100%;
    left: 0%;
    border: 3px solid var(--v2-warning-bkg);
  }
  99% {
    width: 100%;
    left: 0%;
    border: 3px solid var(--v2-warning-bkg);
  }
  100% {
    border: none;
  }
}

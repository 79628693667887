@import '../../assets/master';
@import './modal.scss';

.preview-card-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  flex-grow: 0;
}

.preview-card {
  width: 100%;
  padding: 1rem;
  @include animation(fadeIn 200ms ease-in);

  @media (min-width: 400px) {
    width: 50%;
  }

  @media (min-width: 600px) {
    width: 33%;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }

  @media (min-width: 1500px) {
    width: 20%;
  }

  @media (max-width: 530px) {
    .content {
      .description,
      .overlay.hover-content .btn,
      .overlay.hover-content .btn.outline,
      .overlay.full-description p {
        font-size: 10px;
      }

      .overlay.hover-content {
        padding: 10px;
        .btn.outline {
          padding: 5px;

          .icon {
            display: none;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    border: 2px solid var(--color4);
    background-color: var(--color2);
    padding: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 1px 4px 9px 0px var(--color3-darker);
    @include transition(all 200ms ease-in);

    &:hover {
      box-shadow: 1px 7px 9px 0px var(--color4);

      .hover-content {
        opacity: 1;
        @include transform(translateY(0));
      }
    }

    &.no-desc {
      .description,
      .full-description,
      .btn.btn.text-only {
        display: none;
      }

      h3 {
        border: none;
        margin: 0;
        padding-bottom: 0;
      }
    }

    .image {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      padding-top: 80%;

      &.fallback {
        position: relative;

        svg {
          width: 100%;
          height: 100%;
          @include absolute(top 0 left 0);
          fill: var(--color4);
        }
      }
    }

    h3,
    p {
      margin: 0;
    }

    h3 {
      font-size: 16px;
      color: var(--color1);
      padding: 15px 0;
    }

    h4 {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
    }

    .description {
      color: var(--color4);
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-top: 2px solid var(--color4);
      padding-top: 15px;
      white-space: pre-wrap;
    }

    .overlay {
      padding: 20px;
      background-color: var(--color3-darker);
      opacity: 0;
      @include transform(translateY(100%));
      @include transition(all 300ms ease-in);
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.show {
        opacity: 1;
        @include transform(translateY(0));
      }
    }

    .hover-content {
      @include absolute(right 0 bottom 0 left 0);
      height: 50%;

      .btn.outline {
        text-transform: uppercase;
        font-size: 14px;
        justify-content: center;
        width: 100%;
        white-space: nowrap;

        &:hover {
          background-color: var(--color4);
        }

        .icon {
          width: 15px;
          height: 15px;
          margin-left: 8px;

          svg {
            fill: var(--color1);
          }
        }
      }

      .btn.text-only {
        @include absolute(right 20px bottom 20px);
      }
    }

    .full-description {
      @include absolute(top 0 right 0 bottom 0 left 0);
      z-index: 3;
      text-align: center;
      transition-duration: 200ms;
      white-space: pre-wrap;

      p {
        color: var(--color1);
        font-size: 14px;
        text-shadow: 1px 1px 10px var(--color4);
      }
    }
  }
}

.iframe-popup {
  @include fixed(top 0 right 0 bottom 0 left 0);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    @include animation(slideUpFadeIn 200ms ease-in);
    border: none;
    width: 50vw;
    height: 90vh;
    @include compact {
      width: 90vw;
    }
  }
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .number-display {
    margin: 0 10px;
    padding: 10px;
    background-color: var(--color3);
    width: 38px;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  .btn.icon {
    padding: 0;
    background-color: transparent;
    width: 15px;
    height: 15px;

    &:hover {
      svg {
        fill: var(--color1-lightest);
      }
    }

    svg {
      fill: var(--color1);
    }
  }
}

.toast {
  display: inline-flex;
  margin-bottom: 10vh;
  padding: 20px;
  background-color: var(--color1-lightest);
  color: var(--color2);
  font-weight: bold;
  @include animation(slideUpFadeIn 200ms ease-in);
  box-shadow: 1px 4px 9px 0px var(--color3-darker);

  &.error {
    background-color: var(--error-background);
    color: var(--error-foreground);
  }

  h3 {
    margin: 0;
  }
}

#custom-overlay {
  background-size: 'contain';
  background-repeat: 'no-repeat';
  background-position: 'center center';
}

#image-overlay {
  display: none;
  object-fit: cover;
  object-position: center center;
  @include absolute(top 0 left 0);
  width: calc(100vw - var(--modelContentMenuWidth));
  height: 100%;
  z-index: -1;
  transition: opacity 200ms ease-in-out;

  @include compact() {
    width: 100%;
  }
}

#progress-window {
  max-width: 45vw;
  max-height: 95vh;
  overflow: hidden;
  padding: 40px;
  background-color: var(--color2);
  color: var(--color1);
  border-radius: 4px;
  border: 1px solid var(--color4);
  display: flex;
  flex-direction: column;

  @include compact() {
    width: auto;
    max-width: 95vw;
  }

  #logo-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
      text-align: center;
    }

    #client-logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-bottom: 0.5rem;
        max-height: 160px;
        max-width: 260px;
        flex-shrink: 0;
      }
    }

    #title {
      font-size: 36px;
      font-weight: bold;
    }

    #model-name {
      font-size: 18px;
      font-weight: bold;
    }
  }

  #info-box {
    #progress-bar {
      width: 100%;
      height: 25px;
      // background-color: var(--color4);
      border: 1px solid #8a8a8a;
      border-radius: 4px;
      margin: 1rem 0;

      #progress {
        @include transition(width 400ms ease-in);
        width: 0;
        height: 100%;
        background-color: var(--color4);
      }
    }

    > div {
      width: 100%;
      text-align: center;
    }

    #description {
      font-size: 18px;
    }

    #disclaimer {
      font-size: 12px;
      padding-top: 10px;
      color: var(--color1-lightest);
    }
  }
}

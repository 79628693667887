:root {
  /* Color settings with transparency and luminance modifiers - see ThemeService */
  /* Primary Foreground Setting*/
  --color1: black;
  /* Primary Background Setting*/
  --color2: white;
  /* Secondary Background Setting*/
  --color3: #f2f2f2;
  /* Accent Setting*/
  --color4: #ccc;

  /* Other settings */
  --primaryfont: Roboto;

  /* Hardcoded (no settings) */
  --modelContentMenuWidth: 300px;
  --transparent-gray: #44444499;
  --transparent-light-gray: #cccccc99;
  --tranparent-color-3: #f2f2f2ee;
  --logo-background: transparent;
  --error-background: #fd5757;
  --error-foreground: #ffffff;

  /* Mobile color variable (Only used in the V1 menu) */
  --mobile-color1: #3c709d;

  /* V2 colors Leaving these commented for reference as they are the colors of the original design*/
  /* --v2-primary-frg: #3c709d;
  --v2-primary-frg-darker: #345e83;
  --v2-primary-bkg: #fff; */

  --v2-primary-frg: var(--color1);
  --v2-primary-frg-darker: var(--color1-darker);
  --v2-primary-bkg: var(--color2);
  --v2-secondary-bkg: var(--color3);
  --v2-accent: var(--color4);
  --v2-accent-darker: var(--color4-darker);

  --v2-warning-bkg: #ff7f00;
  --v2-transparent-warning-bkg: #ff7f0066;
  --v2-warning-frg: #fff;
  --v2-transparent-warning-frg: #ffffffbb;

  --v2-modal-bkg: #fff;
  --v2-btn-overlay: #294f70;
  --v2-overlay-bkg: rgba(24, 24, 24, 0.77);

  /* Background used in the series list menu on mobile*/
  --v2-transparent-menu-color: #f3f3f3dd;

  /*V2 General Variables*/
  --v2-mobile-top-header-height: 50px;
}
